import AboutImage from '../../assets/Arnold2.jpg'
import CV from '../../assets/CV.pdf'
import {HiDownload} from 'react-icons/hi'
import data from './data'
import Card from '../../components/Card'
import './about.css'

const About = () => {
    const handleCardClick = (link) => {
        console.log('Clicked card:', link);
        window.location.href = link;
      };
  return (
<section id="about" data-aos="fade-in">
        <div className="container about__container">
            <div className="about__left">
                <div className="about__portrait">
                    <img src={AboutImage} alt="About " />
                </div>
            </div>
            <div className="about__right">
                <h2>A Propos de moi</h2>
                <div className="about__cards">
                    {
                        data.map(item => (
                            <Card key={item.id} className="about__card" onClick={() => handleCardClick(item.link)}>
                                <span className='about__card-icon'>{item.icon}</span>
                                <h5>{item.title}</h5>
                                <small>{item.desc}</small>
                            </Card>
                        ))
                    }
                </div>
                <p>
                 ma passion pour le développement informatique s'est transformée en une quête professionnelle pour créer des solutions numériques innovantes. Spécialisé en développement Full Stack, j'ai acquis une expertise solide dans des langages et des technologies tels que C++, C#, Java, HTML, CSS, Bootstrap, React et Node.js. Chaque ligne de code que j'écris est le reflet de mon engagement à produire des applications performantes, efficaces et esthétiquement plaisantes.                </p>
                <p>
                Que vous ayez besoin d'un site web réactif, d'une application web complexe ou d'une solution back-end robuste, je suis prêt à relever le défi. Ensemble, transformons votre vision en une réalité numérique exceptionnelle.                </p>
                <a href={CV} download className='btn primary'>Download CV <HiDownload/></a>
            </div>
        </div>
    </section>  )
}

export default About