import { RiCss3Fill, RiHtml5Fill, RiJavascriptFill, RiReactjsFill } from "react-icons/ri"
import {BiLogoCPlusPlus, BiLogoNodejs} from "react-icons/bi"
import { SiCsharp, SiPhpmyadmin } from "react-icons/si"
import { FaJava } from "react-icons/fa"
import {PiFileSqlFill} from "react-icons/pi"
const Icondata = [
    {
        id: 1, 
        icon: <RiJavascriptFill/>, 
        text: 'Javascript'
    },
    {
        id: 2, 
        icon: <RiHtml5Fill/>, 
        text: 'Html5', 
    },
    {
        id: 3, 
        icon: <RiCss3Fill/>, 
        text: 'CSS3', 
    },
    {
        id: 4, 
        icon: <BiLogoCPlusPlus/>, 
        text: 'C++', 
    },
    
    {
        id: 5, 
        icon: <SiCsharp/>, 
        text: 'C#', 
    },
    {
        id: 6, 
        icon: <FaJava/>, 
        text: 'Java', 
    },
    {
        id: 7, 
        icon: <RiReactjsFill/>, 
        text: 'React.js', 
    },
    {
        id: 8, 
        icon: <SiPhpmyadmin/>, 
        text: 'Php', 
    },
    {
        id: 8, 
        icon: <PiFileSqlFill/>, 
        text: 'SQL', 
    },
    {
        id: 9, 
        icon: <BiLogoNodejs/>, 
        text: 'Node.js', 
    }
]


export default Icondata