import './navbar.css'
import {IoIosColorPalette} from 'react-icons/io'
import Logo from '../../assets/bit_white.png'
import { useModalContext } from '../../context/modal-context'

import data from './menu_item-data'
const Navbar = () => {
  const {showModalHandler} = useModalContext();

  return (
    <nav>
    <div className="container nav__container">
      <a href="index.html" className='nav__logo'>
        <img src={Logo} alt="Logo" />
      </a>
      <ul className='nav__menu'>
        {
          data.map(item => <li key={item.id}><a href={item.link}>{item.title}</a></li>)
        }
      </ul>
      <button id='theme__icon' onClick={showModalHandler}><IoIosColorPalette/></button>
    </div>
  </nav>  )
}

export default Navbar