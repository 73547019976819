import {HiOutlineMail} from 'react-icons/hi'


const data = [
    {id: 1, icon: <HiOutlineMail/>, link: 'mailto:adadjisso.arnold@gmail.com'},

]





export default data