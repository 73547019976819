import {SiAdobexd} from 'react-icons/si'
import {RiReactjsLine} from 'react-icons/ri'
import {FaServer} from 'react-icons/fa'
import {AiFillAppstore} from 'react-icons/ai'


const data = [
    {
        id: 1, 
        icon: <SiAdobexd/>, 
        title: 'UI/UX Design', 
        desc: "Créer des expériences mémorables est ma spécialité. Avec une esthétique moderne et une navigation intuitive, je m'assure que vos utilisateurs se sentent engagés et satisfaits."
    },
    {
        id: 2, 
        icon: <RiReactjsLine/>, 
        title: 'Développement Frontend', 
        desc: "Immergez vos utilisateurs dans une interface fluide et réactive. Peu importe l'appareil, votre produit brillera par sa performance et son adaptabilité."
    },
    {
        id: 3, 
        icon: <FaServer/>, 
        title: 'Développement Backend', 
        desc: "Protégeons ensemble le cœur de votre business. La sécurité est au centre de mes préoccupations, garantissant une robustesse à toute épreuve contre les menaces externes."
    },
    {
        id: 4, 
        icon: <AiFillAppstore/>, 
        title: 'Développement d\'Applications', 
        desc: "Imaginez une application qui transcende les plateformes. Je développe pour IOS et Android, garantissant une expérience homogène sans coûts supplémentaires."
    }
    
]


export default data