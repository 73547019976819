import './competenceIcon.css'

const CompetenceIcon = ({ iconImage, IconText}) => {
  return (
    <div className="icon_item">
              <div className="icon__image">{iconImage}</div>
              <p className='icon__text'>{IconText}</p>
    </div>
  )
}

export default CompetenceIcon