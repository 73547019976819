import './header.css'
import HeaderImage from '../../assets/Arnold1.jpg'
import data from './header_profil_link_data'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './header.css'
import { useEffect } from 'react'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])

  return (
    <header id="header">
    <div className="container header__container">
      <div className="header__profile" data-aos="fade-in">
        <img src={HeaderImage} alt="Header Portait" />
      </div>
      <h3  data-aos="fade-up">Kokouvi Adadjisso</h3>
      <p  data-aos="fade-up">
      Transformez vos visions en réalité numérique. Partagez les détails de votre rêve avec moi et ensemble, nous créerons une expérience web exceptionnelle, réactive et inoubliable. Votre futur numérique commence ici et maintenant."      </p>
      <div className="header__cta"  data-aos="fade-up">
        <a href="#contact" className='btn primary'>Discutons-en</a>
        <a href="#portfolio" className='btn light'>Mon travail</a>
      </div>
      <div className="header__socials">
        {
          data.map(item => <a key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">{item.icon}</a>)
        }
      </div>
    </div>
  </header>  )
}

export default Header