import './portfolio.css'
import Projects from './Projects'
import ProjectsCategories from './ProjectsCategories'
import data from './data'
import { useState } from 'react'

const Portfolio = () => {
  const [projects, setProjects] = useState(data);

  const categories = data.reduce((acc, item) => {
    item.category.forEach(category => {
        if (!acc.includes(category)) {
            acc.push(category);
        }
    });
    return acc;
}, ['all']);
  const uniqueCategories = [...new Set(categories)];
  
  const filterProjectsHandler = (category) => {
    if(category === "all") {
      setProjects(data);
      return;
    }

    const filterProjects = data.filter(project => project.category.includes(category));
    setProjects(filterProjects);
  }
  

  return (
    <section id="portfolio">
      <h2>Projets recents</h2>
      <p>
        Voici quelques uns des projets personnels sur lesquels j'ai recemment travaillé
      </p>
      <div className="container portfolio__container">
        <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
        <Projects projects={projects}/>
      </div>
    </section>
  )
}

export default Portfolio