import Image1 from '../../assets/contact_manager.jpg'
import Image2 from '../../assets/game_play.jpg'
import Image3 from '../../assets/electronik.jpg'
import Image4 from '../../assets/jeu_memoire.jpg'
import Image5 from '../../assets/commande_gest.jpg'
import Image6 from '../../assets/dasahboard.jpg'
import Image7 from '../../assets/battleship.jpg'
import Image8 from '../../assets/playlist.jpg'
import Image9 from '../../assets/easybooking.jpg'
import Image10 from '../../assets/AIsum.png'
import Image11 from '../../assets/Systeme_Bancaire.jpg'
import Image12 from '../../assets/car_society.jpg'


const data = [
    {
        id: 1,
        category: ['C#'],
        image: Image1,
        title: "Contact Manager (C#)",
        desc: "GestContactPro est une application moderne de gestion de contacts, conçue pour offrir une expérience utilisateur fluide et intuitive. Grâce à l'utilisation de C# couplée à Windows Presentation Foundation (WPF), l'application présente une interface riche et responsive, optimisée pour une utilisation quotidienne.",
        github: 'https://github.com/Arnold9025/contact_manager'

    },
    {
        id: 2,
        category: ['HTML/CSS'],
        image: Image2,
        title: "Site de jeu video",
        desc: "Bienvenue dans PlayZone, une plateforme de jeu en ligne qui repousse les limites de l'expérience front-end. Sans la nécessité d'un backend, notre site offre une approche simplifiée et directe des jeux vidéo en ligne, garantissant des chargements rapides, une interactivité fluide et une immersion totale.",
        github: 'https://github.com/Arnold9025/playZone'
    },
    {
        id: 3,
        category: ['Java','HTML/CSS'],
        image: Image3,
        title: "Electronick (JAVA)",
        desc: "un site web e-commerce spécialisé dans la vente de produits électroniques. L'architecture du site a été soigneusement conçue pour offrir aux utilisateurs une expérience d'achat fluide et intuitive tout en garantissant une gestion efficace des produits.",
        github: 'https://github.com/Arnold9025/electronik'
    },
    {
        id: 4,
        category: ['Javascript', 'HTML/CSS'],
        image: Image4,
        title: "Jeu de memoire",
        desc: "Plongez dans un défi passionnant avec notre jeu de mémoire interactif ! Le concept est simple et pourtant captivant : tournez les cartes et trouvez les paires identiques pour gagner des points. Chaque paire correctement identifiée vous rapproche de la victoire, mais attention à ne pas vous tromper trop souvent, car le jeu nécessite à la fois concentration et rapidité.",
        github: 'https://github.com/Arnold9025/Jeu_de_memoire'
    },
    {
        id: 5,
        category: ['PHP',  'HTML/CSS'],
        image: Image5,
        title: "Commande Gest",
        desc: "Application de gestion de commande qui permet à l’utilisateur d’ajouter des commandes, de modifier l'état des commandes et de supprimer les commandes dans une base de donnee.",
        github: 'https://github.com/Arnold9025/commandeGest'
    },
    {
        id: 6,
        category: ['React'],
        image: Image6,
        title: "React Dashboard",
        desc: "Application de Simulation d'un dashboard Admin d'un site E-commerce, Adaptative et Responsive.",
        github: 'https://github.com/Arnold9025/reatc_Dashboard/',
        view: 'https://65e1f48ca881b5f0c9953538--reactandchart-dashboard.netlify.app/'

    },
    {
        id: 7,
        category: ['Java'],
        image: Image7,
        title: "Jeu BattleShip",
        desc: "une application desktop (Frame) permettant à un utilisateur de jouer à la Bataille navale (BattleShip) contre l’ordinateur ou contre un autre joueur en réseau. Tour à tour le joueur et l'ordinateur (ou l'autre joueur) tirent sur la grille de l'adversaire",
        github: 'https://github.com/Arnold9025/BattleShip'
    },
    {
        id: 8,
        category: ['React', 'Javascript', 'Node.Js', 'HTML/CSS'],
        image: Image8,
        title: "Discogs Playlist",
        desc: "Concevoir une application permettant la gestion d'une sélection. (Playlist) Le projet comporte 2 parties, un serveur et un client. Le serveur offre un API REST en JSON et permet une persistance dans la base de données PostgreSQL. Le client permet la recherche de musique sur le serveur https://www.discogs.com/ et la création de liste de musique stockable dans une base de données Maria DB Local. ",
        github: 'https://github.com/Arnold9025/Discogs-playlist'
    },
    {
        id: 9,
        category: ['React', 'Javascript', 'Node.Js', 'HTML/CSS'],
        image: Image9,
        title: "EasyBooking",
        desc: "Le projet EASY BOOKINGS est une application de réservation en ligne conçue pour simplifier le processus de recherche et de réservation de vols et d'hôtels. Cette application offre aux utilisateurs une expérience conviviale, intuitive et sécurisée pour organiser leurs voyages en quelques clics.",
        github: 'https://github.com/Arnold9025/Easy-booking'
    },
    {
        id: 10,
        category: ['React', 'Javascript', 'Node.Js', 'HTML/CSS'],
        image: Image10,
        title: "Ai summarizer",
        desc: "Flemme de lire un article ? Copies son lien dans la barre de notre application web qui grace a la puissance de Chat GPT 4, te fait un resume de l'article ",
        github: 'https://github.com/Arnold9025/Easy-booking',
        view: 'https://65e1f81951e5edf687301235--openai4summarizer.netlify.app/'

    },
    {
        id: 11,
        category: ['C'],
        image: Image11,
        title: "Systeme Bancaire",
        desc: "Ce programme de gestion de comptes bancaires permet à l'utilisateur d'effectuer diverses opérations, telles que l'ajout de nouveaux comptes, la désactivation de comptes existants, l'affichage des informations d'un compte spécifique, l'effectuation de dépôts et de retraits, ainsi que l'affichage de statistiques telles que la somme totale des soldes de tous les comptes et le solde moyen des comptes actifs",
        github: 'https://github.com/Arnold9025/Systeme_Bancaire',

    },
    {
        id: 12,
        category: ['React', 'Javascript', 'Node.Js', 'HTML/CSS'],
        image: Image12,
        title: "Car Society",
        desc: "Car society est une plateforme adaptative pour tout les business  de locations et de ventes de voiture, car society exploite l'API Cars Ninja pour afficher les informations des voitures et IMAGINZ pour afficher les images ",
        github: 'https://github.com/Arnold9025/car_society',
        view: 'https://carsociety.netlify.app/'

    },

]


export default data