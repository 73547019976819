import {TbBooks} from 'react-icons/tb'
import { HiCalendar, HiLightBulb } from 'react-icons/hi'

const data = [
    {id: 2, icon: <TbBooks/>, title: 'Projets', desc: '10+ Complete', link: '#portfolio'},
    {id: 3, icon: <HiLightBulb/>, title: 'Langages', desc: '7+ Langages maitrisé', link: '#services'},
    {id: 3, icon: <HiCalendar/>, title: 'Methodologies', desc: 'Agile', link: '#services'}

    ]


    export default data;