import {AiOutlineLinkedin} from 'react-icons/ai'

import {AiFillGithub} from 'react-icons/ai'

export const links = [
    {id: 1, link: '#', title: 'Accueil'},
    {id: 2, link: '#about', title: 'A propos'},
    {id: 3, link: '#services', title: 'Competences'},
    {id: 4, link: '#portfolio', title: 'Projets'},
    {id: 5, link: '#contact', title: 'Contact'}
]


export const socials = [
    {id: 1, link: 'https://www.linkedin.com/in/arnold-adadjisso-5a5252245/', icon: <AiOutlineLinkedin/>},
    {id: 2, link: 'https://github.com/Arnold9025', icon: <AiFillGithub/>}
]