import Card from '../../components/Card'
import data from './data'
import './services.css'
import Icondata from './icons-data'
import CompetenceIcon from '../../components/CompetenceIcon'
const Services = () => {
  return (
    <section id="services">
      <h2>Mes Competences</h2>
      <p>Je vous aide à</p>
      <div className="container services__container" data-aos="fade-up">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service__icon">{item.icon}</div>
              <div className="service__details">
                <h4>{item.title}</h4>
                  <p>{item.desc}</p>
              </div>
            </Card>
          ))
        }
      </div>
        <h2 style={{marginTop: '50px', marginBottom: '50px'}}>Competences Techniques</h2>
        
      <div className='competence__icon__palette container services__container' data-aos="fade-in">
      {
        Icondata.map(item => (
          <CompetenceIcon key={item.id} iconImage={item.icon} IconText={item.text} />
        ))
      }

      </div>
    </section>
  )
}

export default Services